html,
body,
#container {
	height: 100%;
	min-height: 100%;
}

body {
	background: @f7Szurke;
	color: @betuSzin;
	font-family: @basicFont;
	font-size: @paragraphFontSize;
	line-height: @baseLineHeight;
}

::-moz-focus-inner {
	border: 0;
}

/* TODO: különböző betűméretek */
h1,h2,h3,h4,h5,h6 {
	font-weight: normal;
	font-family: 'novecento_widenormal';
	margin: 0 0 .5em 0;
	letter-spacing: 5px;
}

h2 {
	letter-spacing: 5px;
}

h2.feher,
.szoveg.feher {
	color: #fafafa;
}

.aloldal {
	h1,h2,h3,h4,h5,h6 {

	}
}

a {
	&, &:visited, &:active, &:hover {
		color: inherit;
	}
}

.container {
	width: 100%;
	max-width: @containerWidth;
	margin: 0 auto;
	position: relative;

	&.tartalom {
		padding-top: 25px;
	}
}

hr {
	margin: 10px 0;
	height: 0;
	border-top: 1px solid @vilagosSzurke;
}

// alap grid-szerű cucc. használata pl.: <div class="col col1-3">
// muszáj definiálni: @containerWidth és @columnMargin
.col {
	float: left;
	.box-sizing(border-box);

	.colWidth(@colspan, @osszes){
		width: floor((@containerWidth - (@columnMargin * (@osszes - 1))) / @osszes) * @colspan + (@colspan - 1) * @columnMargin;
	}

	&.col1-2 {
		.colWidth(1,2);
	}

	&.col1-3 {
		.colWidth(1,3);
	}

	&.col2-3 {
		.colWidth(2,3);
	}

	&.col1-4 {
		.colWidth(1,4);
	}

	&.col3-4 {
		.colWidth(3,4);
	}

	&.col1-6 {
		.colWidth(1,6);
	}

	&.col5-6 {
		.colWidth(5,6);
	}

	& + & {
		margin-left: @columnMargin;
	}
}

.teljes {
	width: 100%;
}

.fele {
	width: 50%;
}

.harmada {
	width: 33%;
}

.negyede {
	width: 25%;
}

.fixed {
	position: fixed;
}

.absolute {
	position: absolute;
}

.vonalas > span {
	display: inline-block;
	position: relative;
}
.vonalas > span:before,
.vonalas > span:after {
	content: "";
	position: absolute;
	height: 14px;
	border-bottom: 2px solid @vonalSzurke;
	top: 0;
	width: 12px;
}
.vonalas > span:before {
	right: 100%;
	margin-right: 10px;
}
.vonalas > span:after {
	left: 100%;
	margin-left: 10px;
}

.fieldset {
	border: 1px solid @betuSzurke;
	padding: 10px 18px;
	margin: 1em 2px;

	& > h2,
	& > h3 {
		font-size: 1em;
		font-weight: normal;
		margin: -1.2em -0.5em 0;
		font-family: @headerFont;

		& > span {
			display: inline-block;
			padding: 0 10px;
			background-color: #fff;
		}

	}
}

#preloader {

}

#preloader {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	//background: #777 url(../img/gif-load.gif) no-repeat center center;
	//background-size: 50px 50px;
	background-color: #777;
	z-index: 10;
	display: table;

	#prelodader-center {
		width: 300px;
		height: 250px;
		display: table-cell;
		vertical-align: middle;
		text-align: center;
	}

	#preloader-logo {
		width: 210px;
		height: 145px;
		background: url(../img/vatyi_erika_logo_preloader.png) no-repeat 0 0 transparent;
		margin: 0 auto;
	}
}

#preloader-spinner:before,
#preloader-spinner:after,
#preloader-spinner {
	border-radius: 50%;
	width: 1em;
	height: 1em;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation: load7 1.8s infinite ease-in-out;
	animation: load7 1.8s infinite ease-in-out;
}
#preloader-spinner {
	font-size: 10px;
	margin: 0 auto;
	position: relative;
	text-indent: -9999em;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}
#preloader-spinner:before {
	left: -2.5em;
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}
#preloader-spinner:after {
	left: 2.5em;
}
#preloader-spinner:before,
#preloader-spinner:after {
	content: '';
	position: absolute;
	top: 0;
}
@-webkit-keyframes load7 {
	0%,
	80%,
	100% {
		box-shadow: 0 2.5em 0 -1.3em #aaaaaa;
	}
	40% {
		box-shadow: 0 2.5em 0 0 #aaaaaa;
	}
}
@keyframes load7 {
	0%,
	80%,
	100% {
		box-shadow: 0 2.5em 0 -1.3em #aaaaaa;
	}
	40% {
		box-shadow: 0 2.5em 0 0 #aaaaaa;
	}
}

.ajanlat {
	background-color: #fff;
	padding: 10px;
}

.ajanlat {
	.fieldset {
		& > h2,
		& > h3 {
			font-size: 14px;
			text-transform: uppercase;
			letter-spacing: 1.5px;
			color: #535353;

			& > span {
				//				background-color: @f7Szurke;
			}

		}
	}
}

button {
	padding: 6px 5px;
	background-color: #575757;
	border: none;

	&:hover {
		background-color: #383838;

		span {
			background-color: #383838;
		}
	}

	span {
		display: block;
		padding: 4px 10px;
		border: 1px solid #b9b9b9;
		background-color: #575757;
		color: #fbfbfb;
		font-size: 10px;
		text-transform: uppercase;
		font-family: 'novecento_widenormal';
		letter-spacing: 1.5px;

		&:hover {
			background-color: #383838;
		}
	}

	&.vilagos {
		background-color: #dddddd;

		&:hover {
			background-color: #bbbbbb;

			span {
				background-color: #bbbbbb;
			}
		}

		span {
			background-color: #dddddd;
			border: 1px solid #efefef;

			&:hover {
				background-color: #bbbbbb;
			}
		}
	}
}

.aloldal {
	button {
		background-color: #dddddd;

		&:hover {
			background-color: #bbbbbb;

			span {
				background-color: #bbbbbb;
			}
		}

		span {
			background-color: #dddddd;
			border: 1px solid #efefef;

			&:hover {
				background-color: #bbbbbb;
			}
		}
	}
}

a.button {
	padding: 6px 5px;
	display: inline-block;
	background-color: #dddddd;
	border: none;
	text-decoration: none;

	&:hover {
		background-color: #bbbbbb;

		span {
			background-color: #bbbbbb;
		}
	}

	span {
		display: block;
		padding: 1px 10px;
		color: #fbfbfb;
		font-size: 10px;
		text-transform: uppercase;
		font-family: 'novecento_widenormal';
		letter-spacing: 1.5px;
		background-color: #dddddd;
		border: 1px solid #efefef;

		&:hover {
			background-color: #bbbbbb;
		}
	}
}

.lead {
	font-size: 17px !important;
}

.alert-success,
.alert-danger {
	background-color: #8A8A8A;
	border-color: #8A8A8A;
	color: #fff;
	.border-radius(0);

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}
}

.modal {
	text-align: center;
}

.modal-backdrop {
	background: url('../vendor/fancybox/fancybox_overlay.png') repeat 0 0 transparent !important;
}

.modal-backdrop.in {
	opacity: 1;
	filter: alpha(opacity=100);
}

@media screen and (min-width: 768px) {
	.modal:before {
		display: inline-block;
		vertical-align: middle;
		content: " ";
		height: 100%;
	}
}

.modal-dialog {
	display: inline-block;
	text-align: left;
	vertical-align: middle;

	button {
		background-color: #575757 !important;

		span {
			display: block;
			padding: 4px 10px;
			border: 1px solid #b9b9b9 !important;
			background-color: #575757 !important;
		}
	}
}

.modal-content {
	background-color: #222;
	border-color: #222;
	color: #fff;
	.border-radius(0);
	font-size: 16px;

	ul li:before {
		content: "" !important;
	}

	.close {
		float: none;
		position: absolute;
		top: -18px;
		right: -18px;
		width: 36px;
		height: 36px;
		cursor: pointer;
		z-index: 8040;
		background-image: url('../vendor/fancybox/fancybox_sprite.png');
		background-color: transparent !important;
		opacity: 1;
		filter: alpha(opacity=100);

		&:hover,
		&:focus {
			background-color: transparent !important;
			opacity: 1;
			filter: alpha(opacity=100);
		}

		span {
			display: none;
		}
	}
}

.modal-header,
.modal-footer {
	border: none;
}

#content {
	ul {
		li {
			text-indent: -5px;
			list-style: none;

			&:before {
				content: "-";
				position: relative;
				left: -5px;
			}
		}
	}
}
