header {

	#logo {
		padding: 0;
		a {
			display: block;
			font-size: 0;
			text-indent: -8000px;
			width: 230px;
			height: 68px;
			background: url(../img/vatyi_erika_logo_fekvo_szurke_vektoros.svg) no-repeat center center transparent;

			-webkit-transition: height 0.3s ease-in;
			-moz-transition: height 0.3s ease-in;
			transition: height 0.3s ease-in;
		}
	}

	.container {
//		padding: 10px 0 0;
	}

	.navbar-nav {
		-webkit-transition: height 0.3s ease-in;
		-moz-transition: height 0.3s ease-in;
		transition: height 0.3s ease-in;

		li {
			padding: 5px 0 0;
			a {
				font-family: 'novecento_demibold';
				font-size: 11px;
				//color: @vilagosSzurke;
				color: rgb(68,68,68) !important;
				letter-spacing: 1.5px;

				&:hover {
					color: #bbb !important;
				}
			}
		}
	}

	.navbar-default {
		border: none;
		border-bottom: 1px solid #d3d3d3;
		background-color: rgb(255,255,255);//@sotetSzurke;
		height: 0;
		min-height: 0;
		overflow: hidden;

		-webkit-transition: height 0.3s ease-in;
		-moz-transition: height 0.3s ease-in;
		transition: height 0.3s ease-in;

		.navbar-nav {
			height: 0;
			-webkit-transition: padding-top 0.3s ease-in, padding-bottom 0.3s ease-in;
			-moz-transition: padding-top 0.3s ease-in, padding-bottom 0.3s ease-in;
			transition: padding-top 0.3s ease-in, padding-bottom 0.3s ease-in;

			* {
				height: 0;
				-webkit-transition: height 0.3s ease-in;
				-moz-transition: height 0.3s ease-in;
				transition: height 0.3s ease-in;
			}

			#logo {

				a {
					height: 0;
				}
			}

			li {
				padding: 0;
				-webkit-transition: padding-top 0.3s ease-in, padding-bottom 0.3s ease-in;
				-moz-transition: padding-top 0.3s ease-in, padding-bottom 0.3s ease-in;
				transition: padding-top 0.3s ease-in, padding-bottom 0.3s ease-in;


				a {
					padding: 0;
					font-size: 0;
					-webkit-transition: padding-top 0.3s ease-in, padding-bottom 0.3s ease-in;
					-moz-transition: padding-top 0.3s ease-in, padding-bottom 0.3s ease-in;
					transition: padding-top 0.3s ease-in, padding-bottom 0.3s ease-in;
				}
			}
		}

		&.alter {

			height: 68px !important;

			.navbar-nav {
				height: 68px;

				&.pull-right {
					padding: 10px 0 0;
				}

				* {
					height: auto;
				}

				#logo {
					padding: 0;

					a {
						height: 68px;
						padding: 0;
					}
				}

				li {
					padding: 5px 0 0;

					a {
						padding: 10px 15px;
						font-size: 11px;
					}

					&:last-child {
						a {
							padding: 10px 0 10px 15px;
						}
					}
				}
			}
		}

	}

	&.aloldal {
		.navbar-default {
			background-color: rgb(255,255,255);//@sotetSzurke;
			height: 68px;
			position: fixed;

			.navbar-nav {
				height: 68px;

				&.pull-right {
					padding: 10px 0 0;
				}

				* {
					height: auto;
				}

				#logo {
					padding: 0;

					a {
						height: 68px;
						padding: 0;
					}
				}

				li {
					padding: 5px 0 0;

					a {
						padding: 10px 15px;
						font-size: 11px;
					}
				}
			}
		}
	}

}