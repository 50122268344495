// inlabel form

@labelColor:		@szurke;
@inputColor:		@betuSzin;
@inputBg:			@vilagosSzurke;
@inputHeight:		30px;
@inputLineHeight:	@inputHeight;
@inputTextIndent:	8px;
@inputPaddingTop:   5px;
@inputPaddingBottom:@inputPaddingTop;
@inputPadding:		@inputPaddingTop 0;
@inputWidth:		270px;
@buttonBg:			@sotetSzurke;
@buttonColor:		@white;
@textareaWidth:     100%;
@textareaHeight:    190px;


.feher {
	input[type=text],
	input[type=email],
	input[type=password],
	textarea {
		border: 1px solid #fff;
	}

	label {
		color: #fff;
	}
}

.szurke {
	input[type=text],
	input[type=email],
	input[type=password],
	input[type=tel],
	textarea,
	.form-control {
		border: 1px solid #bbbbbb;
	}

	label {
		color: #bbbbbb;
	}
}

label {
	color: @labelColor;
	display: block;
	font-weight: normal;
	margin-bottom: 0;
	font-size: 14px;
	line-height: 1.5;
	text-align: left;
}

input[type=text],
input[type=email],
input[type=tel],
input[type=password],
textarea,
select,
.form-control {
	background: none;
	border: 1px solid #fff;
	color: @inputColor;
	display: block;
	font-family: @basicFont;
	height: @inputHeight;
	line-height: @inputLineHeight;
	margin: 0;
	padding: @inputPadding;
	text-indent: @inputTextIndent;
	width: @inputWidth;
	.border-radius(0) !important;

	&:focus {
		border: 1px solid #ccc !important;
		-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(116,116,116,.6);
		box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(116,116,116,.6);
	}

	.fele &,
	.egesz & {
		width: 100%;
	}

	&.egyeb {
		display: none;
		margin-top: 8px;
	}
}

.form-control {
	width: 100%;
	text-indent: 4px;
}

#hirlevel_feliratkozas {
	input[type=text],
	input[type=email] {
		width: 100%;
	}
}

select {
	text-indent: 0;
	.box-shadow(none) !important;

	option:hover:after,
	option:checked:after {
		content: ' ';
		background: #666;
		color: #fff;
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;

		border: none;
		//transition: all .2s ease-in-out;
	}
}



/*select:focus > option:checked {
	background: #000 !important;
}*/

.autowidth {
	input[type=text],
	input[type=email],
	input[type=tel],
	input[type=password],
	textarea {
		width: 100%;
	}

	textarea {
		height: 60px;
	}
}

textarea {
	height: @textareaHeight;
	width: @textareaWidth;
	text-indent: 0;
	padding-left: @inputTextIndent;
	.box-sizing(border-box);
}

.inlabel {
	div {
		position: relative;
		display: table;

		label {
			color: @labelColor;
			cursor: text;
			height: @inputHeight;
			line-height: @inputLineHeight;
			margin: 0;
			padding: @inputPadding;
			position: absolute;
			text-indent: @inputTextIndent;
		}
	}
}


form > div,
.inlabel > div {
	margin-bottom: 10px;
}

form div {
	&.fele, &.negyede {
		.box-sizing(border-box);

		&.fleft {
			padding-right: 10px;
		}

		&.fright {
			padding-left: 10px;
		}
	}

	&.negyede {
		width: 25%;
	}

	&.fele {
		width: 50%;
	}

	&.egesz {
		.box-sizing(border-box);
		width: 100%;
	}
}

input[type=submit],
input[type=button],
input[type=reset],
.btn_anchor {
	background: @buttonBg;
	border: 0 none;
	.box-sizing(content-box);
	color: @buttonColor;
	display: block;
	font-family: @sansSerif;
	height: @inputHeight;
	line-height: @inputLineHeight;
	margin: 0;
	padding: 5px;
	text-align: center;

	&:hover {
		background: @szurke;
	}
}

//dizájnosított checkbox

input[type=checkbox] {
//	display: none;
	float: left;

	& + label {
//		background: none 0 0 no-repeat;
		color: @betuSzin;
		cursor: default;
		display: block;
//		padding: 0 0 0 25px;
		line-height: @baseLineHeight;
		min-height: @baseLineHeight;
		position: static;
		text-indent: 0;
		.userselect(none);
	}

	&:checked + label {
//		background-image: none;
	}
}