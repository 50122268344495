// --------------------------------------------------
// Variables
// --------------------------------------------------

@base-url:				"../";
@fonts-url:				"@{base-url}font/";
@images-url:			"@{base-url}media/images/";

@font-face {
	font-family: 'novecento_demibold';
	src: url('@{fonts-url}novecento/synthview_-_novecentowide-demibold-webfont.eot');
	src: url('@{fonts-url}novecento/synthview_-_novecentowide-demibold-webfont.eot?#iefix') format('embedded-opentype'),
	url('@{fonts-url}novecento/synthview_-_novecentowide-demibold-webfont.woff') format('woff'),
	url('@{fonts-url}novecento/synthview_-_novecentowide-demibold-webfont.ttf') format('truetype'),
	url('@{fonts-url}novecento/synthview_-_novecentowide-demibold-webfont.svg#novecento_widedemibold') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'novecento_widenormal';
	src: url('@{fonts-url}novecento/synthview_-_novecentowide-normal-webfont.eot');
	src: url('@{fonts-url}novecento/synthview_-_novecentowide-normal-webfont.eot?#iefix') format('embedded-opentype'),
	url('@{fonts-url}novecento/synthview_-_novecentowide-normal-webfont.woff') format('woff'),
	url('@{fonts-url}novecento/synthview_-_novecentowide-normal-webfont.ttf') format('truetype'),
	url('@{fonts-url}novecento/synthview_-_novecentowide-normal-webfont.svg#novecento_widenormal') format('svg');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'novecento_widelight';
	src: url('@{fonts-url}novecento/synthview_-_novecentowide-light-webfont.eot');
	src: url('@{fonts-url}novecento/synthview_-_novecentowide-light-webfont.eot?#iefix') format('embedded-opentype'),
	url('@{fonts-url}novecento/synthview_-_novecentowide-light-webfont.woff') format('woff'),
	url('@{fonts-url}novecento/synthview_-_novecentowide-light-webfont.ttf') format('truetype'),
	url('@{fonts-url}novecento/synthview_-_novecentowide-light-webfont.svg#novecento_widelight') format('svg');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'lintelregular';
	src: url('@{fonts-url}lintel/the_northern_block_-_lintel-webfont.eot');
	src: url('@{fonts-url}lintel/the_northern_block_-_lintel-webfont.eot?#iefix') format('embedded-opentype'),
	url('@{fonts-url}lintel/the_northern_block_-_lintel-webfont.woff') format('woff'),
	url('@{fonts-url}lintel/the_northern_block_-_lintel-webfont.ttf') format('truetype'),
	url('@{fonts-url}lintel/the_northern_block_-_lintel-webfont.svg#lintelregular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'lintelitalic';
	src: url('@{fonts-url}lintel/the_northern_block_-_lintel_italic-webfont.eot');
	src: url('@{fonts-url}lintel/the_northern_block_-_lintel_italic-webfont.eot?#iefix') format('embedded-opentype'),
	url('@{fonts-url}lintel/the_northern_block_-_lintel_italic-webfont.woff') format('woff'),
	url('@{fonts-url}lintel/the_northern_block_-_lintel_italic-webfont.ttf') format('truetype'),
	url('@{fonts-url}lintel/the_northern_block_-_lintel_italic-webfont.svg#lintelitalic') format('svg');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'lintelmedium';
	src: url('@{fonts-url}lintel/the_northern_block_-_lintel_medium-webfont.eot');
	src: url('@{fonts-url}lintel/the_northern_block_-_lintel_medium-webfont.eot?#iefix') format('embedded-opentype'),
	url('@{fonts-url}lintel/the_northern_block_-_lintel_medium-webfont.woff') format('woff'),
	url('@{fonts-url}lintel/the_northern_block_-_lintel_medium-webfont.ttf') format('truetype'),
	url('@{fonts-url}lintel/the_northern_block_-_lintel_medium-webfont.svg#lintelmedium') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'lintelbold';
	src: url('@{fonts-url}lintel/the_northern_block_-_lintel_bold-webfont.eot');
	src: url('@{fonts-url}lintel/the_northern_block_-_lintel_bold-webfont.eot?#iefix') format('embedded-opentype'),
	url('@{fonts-url}lintel/the_northern_block_-_lintel_bold-webfont.woff') format('woff'),
	url('@{fonts-url}lintel/the_northern_block_-_lintel_bold-webfont.ttf') format('truetype'),
	url('@{fonts-url}lintel/the_northern_block_-_lintel_bold-webfont.svg#lintelbold') format('svg');
	font-weight: normal;
	font-style: normal;
}

// színek

@black:					#000;
@sotetSzurke:			#222;
@facebook:				#3b5998;
@szurke:				#262626;
@betuSzin:				#666;
@betuSzurke:			#ddd;
@vilagosSzurke:			#aaa;
@vonalSzurke:           #959595;
@asdSzurke:             #545454;
@Ez444Szurke:           #444444;
@f7Szurke:              #f7f7f7;
@vilagosabbSzurke:		#fbfbfb;
@white:					#fff;

// fontok

@lintelregular:         'lintelregular', Helvetica, Arial, sans-serif;
@lintelitalic:          'lintelitalic', Helvetica, Arial, sans-serif;
@lintelmedium:          'lintelmedium', Helvetica, Arial, sans-serif;
@lintelbold:            'lintelbold', Helvetica, Arial, sans-serif;

@basicFont:             @lintelregular;
@sansSerif:				Arial, Helvetica, sans-serif;
@serif:					Georgia, serif;
@headerFont:			'novecento_demibold';

@baseFontSize:			10px; //azért 10px, hogy egyszerűbben lehessen számolni
@baseLineHeight:		1.6em;
@paragraphFontSize:		@baseFontSize * 1.4;
@menuFontSize:			@baseFontSize * 2;

// méretek, pozíciók

@containerWidth:		1200px;
@columnMargin:			20px;

// embedded képek
