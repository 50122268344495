.mediak {
	padding-top: 68px !important;
	.col-md-4{
		padding-left: 10px;
		padding-right: 10px;
		text-decoration: none;
	}
	.media-leiras {
		background-color: #fff;
		color: #525252;
		height: 100%;
		margin-bottom: 30px;
		padding-bottom: 15px;
		> .media-kep {
			max-width: 380px;
			max-height: 215px;
			overflow: hidden;
			position: relative;
			> svg {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				top: 0;
				margin: auto;
				cursor: pointer;
				max-width: 70px;
				//transform: translate(0%, -105%);
				.cls-1, .st0{
					fill: rgba(red(#444),green(#444),blue(#444),0.5);
				}
			}
		}
		> .media-szoveg {
			margin: 15px 11px 0;
			border: 1px solid #ddd;
			border-bottom: 2px solid #efefef;
			min-height: 45px;

			h2 {
				text-align: center;
				font-size: 1em;
				margin-top: 8px;
				font-weight: 400;
				font-family: novecento_demibold!important;
				text-transform: uppercase;
				letter-spacing: 1.5px;
				> span {
					background-color: #fff;
					padding: 0 10px;
				}
			}
		}
		&:hover{
			svg{
				.cls-1, .st0{
					fill: rgba(red(#333),green(#333),blue(#333),0.9);
				}
			}
		}
	}
	.video {

	}
	.hivatkozas {

	}
}