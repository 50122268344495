/*
 * HTML5 Boilerplate
 *
 * What follows is the result of much research on cross-browser styling.
 * Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
 * Kroc Camen, and the H5BP dev community and team.
 */

/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */

html,
button,
input,
select,
textarea {
	color: #222;
}

body {
	font-size: 1em;
	line-height: 1.4;
}

/*
 * Remove text-shadow in selection highlight: h5bp.com/i
 * These selection declarations have to be separate.
 * Customize the background color to match your design.
 */

::-moz-selection {
	background: #b3d4fc;
	text-shadow: none;
}

::selection {
	background: #b3d4fc;
	text-shadow: none;
}

/*
 * A better looking default horizontal rule
 */

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}

/*
 * Remove the gap between images and the bottom of their containers: h5bp.com/i/440
 */

img {
	vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */

textarea {
	resize: vertical;
}

/* ==========================================================================
   Chrome Frame prompt
   ========================================================================== */

.chromeframe {
	margin: 0.2em 0;
	background: #ccc;
	color: #000;
	padding: 0.2em 0;
}

/* ==========================================================================
   Author's custom styles
   ========================================================================== */

// Border Radius
.border-radius(@radius) {
	-webkit-border-radius: @radius;
	-moz-border-radius: @radius;
	border-radius: @radius;
}

#main {
	min-height: 100%;
	position: relative;
}

* {
	outline: none;
}

#container {

	&.container_aloldal {

	}
}

.form-group {
	margin: 0 0 3px;
}

#content {
	padding: 0 0 49px;

	.aloldal {
		padding: 0 0 40px;
	}
}

.paddingos {
	padding-top: 110px;
}

.kispaddingos {
	padding-top: 68px !important;
}

.fancybox-overlay {
	overflow: hidden !important;
}

.fancybox-lock,
.modal {
	overflow: auto;

	.fieldset > h2 {
		text-align: center;
	}

	.fieldset > h2 > span, .fieldset > h3 > span {
		background-color: #222222;
		color: #eeeeee;
	}

	#hirlevel_feliratkozas {
		h2 > span {
			background-color: #222222 !important;
			color: #eeeeee !important;
		}
	}

	.fieldset {
		border: 1px solid #888888;

		label {
			color: #fbfbfb;
			font-family: @lintelregular;
			font-size: 14px;
		}

		button {
			margin-top: 12px;
		}

		input[type=text], input[type=email], input[type=password], textarea {
			border: 1px solid #747474;
		}
	}

	.fieldset-text {
		padding: 15px 0 5px;
	}
}

.fancybox-skin {
	background-color: #222222;
	.border-radius(0);

	.fancybox-inner {

	}
}

#hirlevel_feliratkozas {
	//	padding: 20px;
	#response {
		display: none;
	}
}

/*
.fancybox-nav {
	span {
		visibility: visible;
		width: 30px;
		height: 60px;
		margin-top: -30px;
		background: url(../img/fancy-sprite.png) no-repeat 0 0 transparent;
	}
	&.fancybox-prev {
		span {
			left: -45px;
			background-position: 0 0;
		}
		&:hover {
			span {
				background-position: -100px 0;
			}
		}
	}
	&.fancybox-next {
		span {
			right: -45px;
			background-position: 0 -100px;
		}
		&:hover {
			span {
				background-position: -100px -100px;
			}
		}
	}
}
*/

/*.fancybox-close {
	background: url(../img/fancy-sprite.png) no-repeat 0 -200px transparent;
	width: 56px;
	height: 56px;
	top: -33px;
	right: -33px;

	&:hover {
		background-position: -100px -200px;
	}
}*/

.fancybox-opened {
	.fancybox-title {
		text-align: right;
		font-family: @headerFont;
		color: #fff;
		font-size: 16px;
	}
}

#slider-container {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	height: 100vh;

	#slider {
		height: 100%;
		height: 100vh;
		width: 100%;
		position: relative;

		& > div {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			height: 150vh;
			z-index: 1;

			&.active {
				z-index: 3;
			}
		}
	}

	#slider-text-container {
		position: absolute;
		width: 100%;
		height: 100%;
		height: 100vh;
		top: 0;
		display: table;
		text-align: center;
		z-index: 4;

		#slider-text-div {
			display: table-cell;
			vertical-align: middle;
			//			padding: 175px 0 0;

			.content-logo {
				width: 450px;
				height: 289px;
				background: url(../img/logo.png) no-repeat 0 0 transparent;
				margin: 0 auto;

				-webkit-transition: opacity 0.25s ease-in-out;
				-moz-transition: opacity 0.25s ease-in-out;
				-ms-transition: opacity 0.25s ease-in-out;
				-o-transition: opacity 0.25s ease-in-out;
				transition: opacity 0.25s ease-in-out;

				&.fade {
					zoom: 1;
					filter: alpha(opacity=50);
					opacity: 0.5;
				}
			}
		}
	}
}

.spacer.s_viewport {
	min-height: 100%;
}

#parallax1, #parallax2, #parallax3 {
	overflow: hidden;
}

#parallax1 > div, #parallax3 > div {
	-webkit-transform: translateZ(0);
	-webkit-backface-visibility: hidden;
	-webkit-perspective: 1000px;
}

#parallax1 > div {
	padding-top: 200px;
}

.szolgaltatas-boritokep {
	height: 560px;
	max-height: 60vh;
}

.kapcsolat-boritokep {
	height: 560px;
	max-height: 60vh;
}

.bemutatkozas {
	padding: 25px 0 30px;
	background-color: @f7Szurke;
	font-size: 18px;
	line-height: 1.5;
	position: relative;

	h2 {
		font-size: 35px;
		font-family: 'novecento_widenormal';
		margin: 0 0 30px 0;
	}

	.container {
		//		padding: 0 130px;

		.holder {
			max-width: 730px;
		}
	}

	.ful {
		height: 41px;
		width: 100%;
		position: absolute;
		top: -41px;
		left: 0;
		z-index: 10;
		background-color: #f7f7f7;

		.pocok {
			display: block;
			position: absolute;
			top: -35px;
			left: 50%;
			transform: translate(-50%, 0);
			width: 70px;
			height: 70px;
			background: url(../img/fulpocok_sprite.png) no-repeat 0 0 transparent;
			outline: none;

			&.clickable:hover {
				background-position: -70px 0;
			}
		}
	}
}

#szolgaltatasok_container {
	height: 545px;
	position: relative;

	.szolgaltatasok_ablak {
		overflow: hidden;
		height: 386px;
		position: relative;
		width: 100%;
	}
}

.szolgaltatasok {
	//background: url(../img/vatyi_erika_parallax_bg.jpg) repeat center 0 @f7Szurke;
	//height: 594px;
	height: 1000px;
	width: 100%;
	position: absolute;
}

.szolgaltatasok_text {
	padding: 20px 0 55px;
	position: absolute;
	top: 0;
	width: 100%;

	h2 {
		color: @vilagosabbSzurke;
	}

	h3 {
		color: @betuSzurke;
		font-family: @lintelregular;
		font-size: 18px;
		text-transform: lowercase;
		margin: 0 0 25px;
	}

	.col-md-4 {
		padding: 0 15px;
	}
}

@media (min-width: 768px) {
	body {
		font-size: 10px;
	}
}

@media (min-width: 992px) {
	body {
		font-size: 14px;
	}
}

@media (min-width: 1200px) {

}

@media (max-width: 767px) {

}

@media (min-width: 768px) and (max-width: 991px) {

}

@media (min-width: 992px) and (max-width: 1199px) {

}

.szolgaltatas {
	height: 395px;

	a {
		text-decoration: none;
		background-color: #fff;
	}

	h2 {
		color: @asdSzurke;
		font-family: 'novecento_demibold' !important;
		text-transform: uppercase;
		letter-spacing: 1.5px;

		span {
			font-family: 'novecento_demibold' !important;
			text-transform: uppercase;
			letter-spacing: 1.5px;
		}
	}

	.padder {
		padding: 3px 10px 1px;
	}

	.fieldset {
		height: 135px;
	}
}

.fooldal {
	.szolgaltatas {
		a {
			display: block;

			-webkit-transform: scale(0.3);
			-moz-transform: scale(0.3);
			-o-transform: scale(0.3);
			-ms-transform: scale(0.3);
			transform: scale(0.3);

			-webkit-transition: all 1s ease-in-out;
			-moz-transition: all 1s ease-in-out;
			-o-transition: all 1s ease-in-out;
			-ms-transition: all 1s ease-in-out;
			transition: all 1s ease-in-out;
		}
	}
	.grow {
		.szolgaltatas {
			a {
				-webkit-transform: scale(1);
				-moz-transform: scale(1);
				-o-transform: scale(1);
				-ms-transform: scale(1);
				transform: scale(1);
			}
		}
	}
}

.kapcsolat-grow {
	-webkit-transform: scale(0.3);
	-moz-transform: scale(0.3);
	-o-transform: scale(0.3);
	-ms-transform: scale(0.3);
	transform: scale(0.3);

	-webkit-transition: all 1s ease-in-out;
	-moz-transition: all 1s ease-in-out;
	-o-transition: all 1s ease-in-out;
	-ms-transition: all 1s ease-in-out;
	transition: all 1s ease-in-out;
}

.kapcsolatform {
	&.grow {
		.kapcsolat-grow {
			-webkit-transform: scale(1);
			-moz-transform: scale(1);
			-o-transform: scale(1);
			-ms-transform: scale(1);
			transform: scale(1);
		}
	}
}

.aloldal {
	.szolgaltatas {
		margin-bottom: 30px;

		a {
			display: block;

			-webkit-transform: scale(0.3);
			-moz-transform: scale(0.3);
			-o-transform: scale(0.3);
			-ms-transform: scale(0.3);
			transform: scale(0.3);

			-webkit-transition: all 1s ease-in-out;
			-moz-transition: all 1s ease-in-out;
			-o-transition: all 1s ease-in-out;
			-ms-transition: all 1s ease-in-out;
			transition: all 1s ease-in-out;
		}
	}

	.grow {
		.szolgaltatas {
			a {
				-webkit-transform: scale(1);
				-moz-transform: scale(1);
				-o-transform: scale(1);
				-ms-transform: scale(1);
				transform: scale(1);
			}
		}
	}
}

.ajanlat {
	.group {
		margin: 0 0 40px;
	}
}

.referenciak {
	/*background-color: @Ez444Szurke;
	padding: 0 0 10px;
	border-bottom: 1px solid #757575;*/

	h2 {
		margin: 0 0 25px;
	}
}

a {
	outline: none !important;
}

.jcarousel-wrapper {
	position: relative;
}

.jcarousel-control-prev,
.jcarousel-control-next,
.control-prev,
.control-next {
	position: absolute;
	width: 57px;
	height: 56px;
	background: url(../img/slider_control_sprite_kicsi.png) no-repeat 0 0 transparent;
	font-size: 0;
	text-indent: -8000px;
	margin: auto;
	outline: none !important;
}

.jcarousel-control-prev,
.control-prev {
	background-position: 0 0;
	left: 44px;
	top: 0;
	bottom: 0;

	&:hover {
		background-position: -71px 0;
	}
}

#fooldal-prev {
	left: 20px;
}

.jcarousel-control-next,
.control-next {
	background-position: 0 -70px;
	right: 44px;
	top: 0;
	bottom: 0;

	&:hover {
		background-position: -71px -70px;
	}
}

#fooldal-next {
	right: 20px;
}

.nav-container {
	position: absolute;
	top: 0;
	left: 0;
	margin: auto;
	width: 100%;
	//bottom: 0;
	right: 0;
	height: 640px;

	.rel {
		width: 80%;
		height: 100%;
		margin: auto;
		position: relative;
	}
}

@sliderHeight: 214px;

#referencia-slider-container {
	position: relative;
	height: @sliderHeight;
	overflow: hidden;

	#referencia-slider {
		position: absolute;
		top: 0;
		left: 0;
		width: 20000em;

		.slide {
			float: left;

			a {
				position: relative;
				display: block;
				overflow: hidden;
				height: @sliderHeight;
				width: 380px;

				img {
					position: absolute;
					height: @sliderHeight;
					width: 380px;
					left: 0;
					top: 0;

					-webkit-transform: scale(1.08);
					-moz-transform: scale(1.08);
					transform: scale(1.08);

					-webkit-transition: all 0.5s ease;
					-moz-transition: all 0.5s ease;
					transition: all 0.5s ease;
				}

				.fedo {
					position: absolute;
					background: url(../img/kep_bg.png) repeat 0 0 transparent;
					opacity: 0;
					height: 100%;
					width: 100%;
					color: #f6f6f6;
					font-family: 'novecento_widenormal';
					font-size: 14px;
					text-transform: uppercase;
					letter-spacing: 1.5px;
					display: table;

					-webkit-transition: all 0.5s ease;
					-moz-transition: all 0.5s ease;
					transition: all 0.5s ease;

					.table-cell {
						display: table-cell;
						vertical-align: middle;

					}

					.belso {
						padding: 0 40px;
						line-height: 17px;
						font-family: 'novecento_widenormal';
						letter-spacing: 3px;

						-webkit-transition: all 0.5s ease;
						-moz-transition: all 0.5s ease;
						transition: all 0.5s ease;

						p {
							margin: 5px 0;

						}

						span {
							color: #dddddd;
							font-size: 14px;
							font-family: 'novecento_widenormal';
							text-transform: none;
							font-variant: small-caps;
							font-variant: small-caps;
							display: block;
							padding: 5px 0 0;

						}
					}
				}

				&:hover {
					img {
						-webkit-transform: scale(1);
						-moz-transform: scale(1);
						transform: scale(1);
					}

					.fedo {
						//background: none;
						opacity: 1;

						.belso {
							padding: 0 35px;
							letter-spacing: 4px;
						}
					}
				}
			}
		}
	}
}

.referencia {
	position: relative;
	height: auto;
	overflow: hidden;

	#referencia-slider {
		top: 0;
		left: 0;

		.slide:not(.slick-active) {
			float: left;
			position: relative;

			.sotet {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				background: url(../img/ref_fedo.png) repeat 0 0 transparent;
			}

		}
	}
}

.referencia {
	#referencia-slider-container,
	img {
		height: 640px;
	}

}

.kapcsolat {
	background-color: #f7f7f7;//@Ez444Szurke;
	padding: 10px 0 0;

	.vonalas {
		border-top: 1px solid #bbb;
		padding: 25px 0 35px;
	}

	.container {
		&.vonal {
			border-bottom: 1px solid #bbb;
			margin-bottom: 30px;
		}
	}

	.szoveg {
		font-size: 17px;
		line-height: 30px;
		padding-top: 10px;
		padding-bottom: 40px;
		max-width: 680px;
		color: #444 !important;//#bcbcbc !important;
	}
}

.szoveg,
.text,
.lead {
	a {
		font-family: @lintelbold;
		color: #777 !important;
	}
}

.fooldal {
	.kapcsolatform {
		label{
			color: #444;
		}

		input[type=text], input[type=email], input[type=password], textarea {
			border: 1px solid #bbb;
			color: #444;//#fff;
		}
	}
}

.kapcsolatform {

	p.name,
	p.foglalkozas {
		color: #444;//#dddddd;
	}

	p.name {
		font-family: @lintelbold;
		font-size: 22px;
		line-height: 21px;
		margin: 0 0 6px;
		letter-spacing: 1.5px;
	}

	p.foglalkozas {
		font-family: @lintelitalic;
		font-size: 16px;
		line-height: 19px;
		margin: 0 0 30px;
	}

	div.telefon,
	div.email,
	div.cim {
		color: #444;//#dddddd;
		padding: 0 0 0 40px;
		height: 28px;
		line-height: 28px;
		font-size: 16px;
		margin: 0 0 5px;
	}

	div.telefon {
		background: url(../img/telefon_szurke.png) no-repeat 0 center transparent;
		color: #444;
	}

	div.email {
		background: url(../img/email_szurke.png) no-repeat 0 center transparent;
		color: #444;
	}

	div.cim {
		background: url(../img/haz_szurke.png) no-repeat 0 center transparent;
		color: #444;
	}

	button, button span {
		background-color: #ddd;
	}

	button span {
		border-color: #fff;
	}

	button:hover, button:hover span {
		background-color: #bbb;
	}
}

.informacio {
	padding: 20px 0 0;
	margin: 30px 0 0;
	border-top: 2px solid #e9e9e9;

	div.telefon,
	div.email,
	div.cim {
		color: #666666;
		padding: 0 0 0 60px;
		height: 40px;
		line-height: 40px;
		font-size: 16px;
		font-family: @lintelregular;
	}

	div.telefon {
		background: url(../img/telefon_nagy_bg.png) no-repeat 2% center transparent;
	}

	div.email {
		background: url(../img/email_nagy_bg.png) no-repeat 30% center transparent;
	}

	div.cim {
		background: url(../img/haz_nagy_bg.png) no-repeat 30% center transparent;
	}
}

.aloldal {
	.kapcsolatform {

		p.name,
		p.foglalkozas,
		div.telefon,
		div.email,
		div.cim {
			color: #666;
		}

		div.telefon {
			background: url(../img/telefon_szurke.png) no-repeat 0 center transparent;
		}

		div.email {
			background: url(../img/email_szurke.png) no-repeat 0 center transparent;
		}

		div.cim {
			background: url(../img/haz_szurke.png) no-repeat 0 center transparent;
		}
	}
}

.szoveg.kapcsolatos {
	margin-bottom: 30px;
	font-size: 20px;
	line-height: 30px;
	padding: 10px 0 40px 0;
	color: #666;
}

#gmap {
	height: 300px;
	margin: 40px 0 -40px 0;
}

div.vonal {
	border-bottom: 1px solid @betuSzurke;
	padding: 0 0 10px;
}

#content {
	#filters {
		margin-left: -12px;
		margin-right: -12px;
		margin-bottom: 3vh;
		margin-top: 0;

		li {
			border-right: 1px solid #dddddd;
			height: 14px;
			line-height: 11px;
			padding: 0 12px;
			text-indent: 0;

			&:before {
				position: static;
				left: 0;
				content: "";
			}

			&.noborder {
				border-right: none;
			}
		}

		button {
			outline: none;
			background: none;
			border: none;
			color: #aaaaaa;
			font-family: 'novecento_demibold';
			font-size: 13px;
			text-transform: uppercase;
			letter-spacing: 1.5px;
			padding-top: 0;

			&.active,
			&:hover {
				color: #545454;
			}
		}
	}
}

#isotope {
	margin-left: -8px;
	margin-right: -8px;

	.item {
		padding-left: 8px;
		padding-right: 8px;
		margin-bottom: 16px; //5vh; //45px;

		&:nth-child(7),
		&:nth-child(8),
		&:nth-child(9) {
			//margin-bottom: 200px;
		}

		.referenciadoboz {
			width: 380px;
			height: 214px;

			a {
				position: relative;
				display: block;
				width: 380px;
				height: 214px;
				overflow: hidden;

				img {
					position: absolute;
					width: 380px;
					height: 214px;
					left: 0;
					top: 0;

					-webkit-transform: scale(1);
					-moz-transform: scale(1);
					transform: scale(1);

					-webkit-transition: all 0.5s ease;
					-moz-transition: all 0.5s ease;
					transition: all 0.5s ease;
				}

				.fedo {
					position: absolute;
					background: url(../img/kep_bg.png) repeat 0 0 transparent;
					opacity: 0;
					width: 380px;
					height: 214px;
					color: #f6f6f6;
					font-family: 'novecento_widenormal';
					font-size: 13px;
					text-transform: uppercase;
					letter-spacing: 1.5px;
					display: table;

					-webkit-transition: all 0.5s ease;
					-moz-transition: all 0.5s ease;
					transition: all 0.5s ease;

					.table-cell {
						display: table-cell;
						vertical-align: middle;

					}

					.belso {
						padding: 0 43px;
						line-height: 17px;
						font-family: 'novecento_widenormal';
						letter-spacing: 2px;

						-webkit-transition: all 0.5s ease;
						-moz-transition: all 0.5s ease;
						transition: all 0.5s ease;

						p {
							margin: 5px 0;
						}

						span {
							color: #dddddd;
							font-size: 13px;
							font-family: 'novecento_widenormal';
							text-transform: none;
							font-variant: small-caps;
							font-variant: small-caps;
							display: block;
							padding: 5px 0 0;
						}
					}
				}

				&:hover {
					img {
						-webkit-transform: scale(1.08);
						-moz-transform: scale(1.08);
						transform: scale(1.08);
					}

					.fedo {
						//background: none;
						opacity: 1;
						.belso {
							padding: 0 35px;
							letter-spacing: 3px;
						}
					}
				}
			}
		}
	}
}

.referencia-adatok {
	width: 100%;
	border: none;
	font-size: 14px;
	color: #666666;
	font-family: @lintelregular;
	margin-top: 14px;

	.first {
		color: #262525;
		font-family: 'novecento_widenormal';
		text-transform: uppercase;
		width: 100px;
		font-size: 12px;
		padding: 0 0 0 10px;
	}
}

.boritokep {
	position: relative;

	#text-slider-container {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;

		#text-slider {
			position: relative;
			width: 100%;
			height: 100%;
			overflow: hidden;

			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
			}

			.text-slide {
				position: absolute;
				top: 0;
				right: -100%;
				width: 100vw;
				height: 100%;
				display: table;
				z-index: -1;
				opacity: 0.2;

				&.active {
					z-index: 4;
				}

				.text-slide-inner {
					width: 100%;
					height: 100%;
					display: table-cell;
					vertical-align: middle;

					.text-slide-content {
						color: #fff;
						font-size: 17px;
						width: 600px;
						font-family: 'novecento_widenormal';
						//						border: 1px solid;
						line-height: 1.1;
						letter-spacing: 3.5px;
					}
				}
			}
		}
	}
}

.form-control:focus,
textarea:focus {
	border-color: #fff;
	outline: 0;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(116, 116, 116, .6);
	-moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(116, 116, 116, .6);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(116, 116, 116, .6);
}

:invalid {
	box-shadow: none;
}

:-moz-submit-invalid {
	box-shadow: none;
}

:-moz-ui-invalid {
	box-shadow: none;
}

/* ==========================================================================
   Helper classes
   ========================================================================== */

/*
 * Image replacement
 */

.ir {
	background-color: transparent;
	border: 0;
	overflow: hidden;
	/* IE 6/7 fallback */
	*text-indent: -9999px;
}

.ir:before {
	content: "";
	display: block;
	width: 0;
	height: 150%;
}

/*
 * Hide from both screenreaders and browsers: h5bp.com/u
 */

.hidden {
	display: none !important;
	visibility: hidden;
}

/*
 * Hide only visually, but have it available for screenreaders: h5bp.com/v
 */

.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

/*
 * Extends the .visuallyhidden class to allow the element to be focusable
 * when navigated to via the keyboard: h5bp.com/p
 */

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
	clip: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	position: static;
	width: auto;
}

/*
 * Hide visually and from screenreaders, but maintain layout
 */

.invisible {
	visibility: hidden;
}

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */

.clearfix:before,
.clearfix:after {
	content: " "; /* 1 */
	display: table; /* 2 */
}

.clearfix:after {
	clear: both;
}

/*
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */

.clearfix {
	*zoom: 1;
}

/* ==========================================================================
   EXAMPLE Media Queries for Responsive Design.
   Theses examples override the primary ('mobile first') styles.
   Modify as content requires.
   ========================================================================== */

@media only screen and (min-width: 35em) {
	/* Style adjustments for viewports that meet the condition */
}

@media print,
(-o-min-device-pixel-ratio: 5/4),
(-webkit-min-device-pixel-ratio: 1.25),
(min-resolution: 120dpi) {
	/* Style adjustments for high resolution devices */
}

/* ==========================================================================
   Print styles.
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */

@media print {
	* {
		background: transparent !important;
		color: #000 !important; /* Black prints faster: h5bp.com/s */
		box-shadow: none !important;
		text-shadow: none !important;
	}

	a,
	a:visited {
		text-decoration: underline;
	}

	a[href]:after {
		content: " (" attr(href) ")";
	}

	abbr[title]:after {
		content: " (" attr(title) ")";
	}

	/*
	 * Don't show links for images, or javascript/internal links
	 */
	.ir a:after,
	a[href^="javascript:"]:after,
	a[href^="#"]:after {
		content: "";
	}

	pre,
	blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}

	thead {
		display: table-header-group; /* h5bp.com/t */
	}

	tr,
	img {
		page-break-inside: avoid;
	}

	img {
		max-width: 100% !important;
	}

	@page {
		margin: 0.5cm;
	}

	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}

	h2,
	h3 {
		page-break-after: avoid;
	}
}

.uzenet-modal {
	> .modal-dialog {
		width: 450px;
		background-color: #222222;
		padding: 20px;
		box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
		> .modal-content {
			box-shadow: none;
			border: 1px solid #888888;
			> .modal-header {
				> h4 {
					margin-top: -25px;
					> span {
						padding: 0 20px;
						display: inline-block;
						background: #222;
					}
				}
			}
			> .modal-body {
			}
		}
	}
}

#videoModal {

	.modal-content {
		padding: 40px 40px;

		.keret {
			border: 1px solid #888888;

			> .modal-header {
				> h4 {
					margin-top: -25px;
					> span {
						padding: 0 20px;
						display: inline-block;
						background: #222;
					}
				}
			}

			.modal-body {
				padding: 10px 25px 20px;
			}

			.modal-footer {
				margin-top: 0;
				padding-top: 0;
				.bezar-gomb {
					border-radius: 0 !important;
					padding: 6px 5px;
					border: none;
					background-color: #575757;
					> span {
						display: block;
						padding: 4px 10px;
						border: 1px solid #b9b9b9;
					}
				}
			}
		}
	}
}